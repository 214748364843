import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Backdrop from "@mui/material/Backdrop";
import { IconButton } from "@mui/material";
import Button from "@mui/material/Button";
import {
  createAssetsWithExcel,
  fetchAssetList,
} from "../../../../../../api/assetOverviewApi";
import { resetState } from "../../../../../component-bundle/asset-overview/assetOverviewSlice";

import { useSnackbar } from "notistack";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { styled } from "@mui/material/styles";
import { DialogActions, DialogContent } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { getDialogBoxHeader } from "../../../../../../utils/helper";
import SaveButton from "../../../../../../components/buttons/SaveButton";

import LinearProgress from "@mui/material/LinearProgress";

const useStyles = styled((theme) => ({
  root: {
    width: "40%",
  },
  proot: {
    width: "80%",
  },
  appBar: {
    position: "sticky",
    zIndex: "0",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  backdrop2: {
    zIndex: 1500,
    color: "#fff",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CreateMultipleAssets = (props) => {
  const { open, onClose, projectInfo, data } = props;
  const dispatch = useDispatch();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const createMulti = useSelector((state) => state.assetOverview.createMulti);
  const scope = useSelector((state) => state.container.scope);
  const [openDialog, setOpenDialog] = useState(open);
  const [loader, setLoader] = useState(false);
  const [rows, setrows] = useState(null);
  const [columns, setColumns] = useState(null);
  const [progressIndex, setprogressIndex] = useState({
    total: null,
    chunkSize: null,
    chunkCount: 0,
    chunkBuffer: 0,
    progressPercentage: 0,
  });

  useEffect(() => {
    const filterData = data.filter(
      (e) => !!e["Asset Name"] && e["Asset Name"] !== ""
    );
    if (filterData.length > 0) {
      if (data.length > filterData.length) {
        enqueueSnackbar(
          `Some data does not have an Asset name however if you decide to save the file the incomplete data will not be saved`,
          {
            variant: "error",
            autoHideDuration: 3000,
          }
        );
      }
      const extractCols = Object.values(filterData)[0];
      const temp = Object.keys(extractCols).map((e) => {
        let header;
        if (e === "Asset Name" || e === "SubAsset Name" ) {
          header = { field: e, headerName: e, flex:1,minWidth:150}
        }else{
         header =  { field: e, headerName: e,flex:0.5,minWidth:90}
        }
        return header ;
      });
      setColumns(temp);
      const rowsValue = Object.values(filterData);
      const rowsWithId = rowsValue.map((e, index) => {
        return { ...e, id: index };
      });
      setrows(rowsWithId);
    }
    if (data.length === filterData.length && filterData.length === 0) {
      enqueueSnackbar("You can't upload a empty asset file", {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  }, [data, enqueueSnackbar]);

  useEffect(() => {
    if (!!createMulti && createMulti.type === "SUCCESS") {
      setprogressIndex({
        ...progressIndex,
        chunkCount: progressIndex.chunkCount + 1,
        progressPercentage:
          (progressIndex.chunkCount / progressIndex.chunkSize) * 100,
        chunkBuffer:
          (progressIndex.chunkCount / progressIndex.chunkSize) * 100 +
          (1 / progressIndex.chunkSize) * 100,
      });
      if (progressIndex.chunkCount + 1 >= progressIndex.chunkSize) {
        setOpenDialog(false);
        setLoader(false);
        createMulti.messages.forEach((element) => {
          enqueueSnackbar(element, {
            variant: "success",
            autoHideDuration: 2000,
          });
        });
        if (typeof onClose === "function") {
          onClose();
        }
        const requestData = {
          api: scope.api,
          projectIdent: scope.projectIdent,
        };
        dispatch(fetchAssetList(requestData));
        dispatch(resetState());
      }
    }
  }, [createMulti, dispatch, enqueueSnackbar, projectInfo, scope]);

  const handleClose = () => {
    setOpenDialog(false);
    setLoader(false);
    if (typeof onClose === "function") {
      onClose();
    }
  };

  const createAssetsInChunks = async (excelRows) => {
    for (let i = 0; i < excelRows.length; i += 3) {
      const chunk = excelRows.slice(i, i + 3);
      await dispatch(
        createAssetsWithExcel({
          api: scope.api,
          excelRows: chunk,
          projectIdent: projectInfo.projectIdent,
        })
      );
    }
  };
  const createAssets = () => {
    setLoader(true);
    const excelRows = rows.map((e, index) => {
      return { ...e, assetUserPermissionsDtos: [], type: "excelRowDto" };
    });
    setprogressIndex({
      ...progressIndex,
      total: excelRows.length,
      chunkSize: Math.ceil(excelRows.length / 3),
      chunkBuffer: (1 / Math.ceil(excelRows.length / 3)) * 100,
    });
    createAssetsInChunks(excelRows);
  };

  return (
    <>
      {!!rows && (
        <>
          <Dialog
            open={openDialog}
            onClose={handleClose}
            fullWidth={true}
            fullScreen
            TransitionComponent={Transition}
            disableBackdropClick={true}
            disableEscapeKeyDown={true}
          >
            <AppBar className="appBar">
              <Toolbar className={"dialog-toolbar"}>
                {getDialogBoxHeader("_Create Asset")}
                <IconButton
                  edge="start"
                  color="inherit"
                  id="create_mutliple_asset_close"
                  onClick={handleClose}
                  aria-label="close"
                  style={{ marginLeft: "auto" }}
                >
                  <CloseIcon />
                </IconButton>
              </Toolbar>
            </AppBar>
            <DialogContent>
              <DataGrid
                style={{ height: "100%" }}
                rows={rows}
                columns={columns}
                disableColumnResize={false}
              />
            </DialogContent>
            <DialogActions>
              <SaveButton
                id="save-asset-01"
                buttonText={"_save"}
                style={{
                  margin: 5,
                  backgroundColor: "#d9534f",
                  color: "#ffffff",
                  height: 40,
                }}
                variant="button"
                tooltip={{
                  title: "_save",
                }}
                handleClick={(e) => createAssets()}
              />
            </DialogActions>
          </Dialog>
          {loader && (
            <Backdrop className={classes.backdrop2} open={loader}>
              <div className={classes.proot}>
                <LinearProgress
                  variant="buffer"
                  value={progressIndex.progressPercentage}
                  valueBuffer={progressIndex.chunkBuffer}
                />
              </div>
            </Backdrop>
          )}
        </>
      )}
    </>
  );
};

export default CreateMultipleAssets;
