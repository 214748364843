import { useState, useEffect } from "react";
import {
  Box,
  ButtonGroup,
  Typography,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Card,
  Popover,
} from "@mui/material";
import PropTypes from "prop-types";
import CustomIconButton from "../../../../../components/buttons/icons-buttons/CustomIconButton";
import CustomButton from "../../../../../components/buttons/CustomButton";

import { getBuildingType } from "../../../../../api/suggestionApi";
import {
  getVariantText,
  getTranslationVariantText,
  getIntlTranslation,
} from "../../../../../utils/helper";
import AssessmentIcon from "@mui/icons-material/Assessment";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import AddIcon from "@mui/icons-material/Add";
import { useDispatch, useSelector } from "react-redux";
import {
  updateReportVersion,
  deleteReportVersion,
  fetchAssetList,
} from "../../../../../api/assetOverviewApi";
import CreateReportVersion from "./CreateReportVersion";
import { getTemp } from "../../../projectmonitoringModuleSlice";
import DeleteConfirmationDialog from "./ReportVersionDeleteConfirmationDialog";
import AssignmentIcon from "@mui/icons-material/Assignment";

const AssetOverviewContainer = (props) => {
  const scope = useSelector((state) => state.container.scope);
  const tempVersion = useSelector((state) => state.projectMonitoring.temp);
  const suggestionState = useSelector((state) => state.suggestions);
  const [buildingType, setBuildingType] = useState([]);

  const [openPopOver, setOpenPopOver] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const getAddress = (address) => {
    return `${address.streetName}, ${address.postalCode} ${address.city}, ${address.state}, ${address.country}`;
  };
  const [openUpdateReportVersion, setOpenUpdateReportVersion] = useState({
    open: false,
    assetIdent: null,
  });
  const [deleteDialogState, setDeleteDialogState] = useState({
    open: false,
    version: null,
    asset: null,
  });

  const dispatch = useDispatch();

  const handleReportVersionOptions = (event, input) => {
    setOpenPopOver(input.ident);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpenPopOver(null);
  };

  const handleTempOnclick = (m, asset) => {
    dispatch(
      getTemp({
        isTemp: true,
        assetName: asset.name,
        assetIdent: m.assetId,
        reportVersionIdent: m.ident,
        reportVersionObject: m,
      })
    );
  };
  const handleReportUpdate = (m) => {
    const temp = {
      type: "reportVersion",
      versionName: m.versionName,
      assetId: m.assetId,
      current: true,
      ident: m.ident,
    };
    dispatch(
      updateReportVersion({
        api: scope.api,
        projectIdent: scope.projectIdent,
        data: temp,
      })
    );
    handleClose();
  };
  const handleUpdateVersionName = (m, val) => {
    const temp = {
      type: "reportVersion",
      versionName: val.versionName,
      assetId: m.assetId,
      current: m.current,
      ident: m.ident,
    };
    dispatch(
      updateReportVersion({
        api: scope.api,
        projectIdent: scope.projectIdent,
        data: temp,
      })
    );
    handleClose();
    setOpenUpdateReportVersion({
      open: false,
      assetIdent: null,
    });
  };
  const handleDeleteVersion = async (m, val) => {
    try {
      await dispatch(
        deleteReportVersion({
          api: scope.api,
          projectIdent: scope.projectIdent,
          ident: m.ident,
          data: {
            type: "reportVersion",
            versionName: val.versionName,
            assetId: m.assetId,
            current: m.current,
            ident: m.ident,
          },
        })
      );

      await dispatch(
        fetchAssetList({
          api: scope.api,
          projectIdent: scope.projectIdent,
        })
      );

      handleClose();
      setOpenUpdateReportVersion({
        open: false,
        assetIdent: null,
      });
      setDeleteDialogState({
        open: false,
        version: null,
        asset: null,
      });
    } catch (error) {
      console.error("Error during version deletion:", error);
    }
  };

  useEffect(() => {
    dispatch(getBuildingType());
  }, [dispatch]);

  useEffect(() => {
    if (
      suggestionState.buildingType.type === "FETCHED" &&
      suggestionState.buildingType.data.length > 0
    ) {
      setBuildingType([...suggestionState.buildingType.data]);
    }
  }, [suggestionState.buildingType]);

  const renderSubAssetSection = (asset, sub, index) => {
    let found = buildingType.find(
      (f) =>
        f.value === sub.typeOfUse && f.language === scope.projectLanguageCode
    );
    let typeOfUse = !!found
      ? found
      : { title: sub.typeOfUse, value: sub.typeOfUse };
    return (
      <Box className={"d-flex flex-wrap"} style={{ width: "100%" }}>
        <div
          className={"d-flex align-items-center flex-wrap"}
          style={{ width: "25%", margin: "5px 10px" }}
        >
          {getTranslationVariantText("_Name", "body1", {
            fontWeight: "bold",
            color: "#254a9a",
          })}
          :
          <span
            className="d-flex align-items-baseline flex-wrap"
            style={{ margin: 5 }}
          >
            {getVariantText(sub.name, "subtitile2", {
              marginRight: 5,
            })}
            {sub.general &&
              getTranslationVariantText("_marked-general", "subtitile2", {
                color: "#d08f8e",
                marginLeft: 5,
              })}
          </span>
        </div>
        <Divider
          orientation="vertical"
          flexItem
          style={{ margin: "20px 5px", backgroundColor: "#254a9a" }}
        />
        <div
          className={"d-flex align-items-center flex-wrap"}
          style={{ width: "15%" }}
        >
          <AccountBalanceIcon
            style={{ fontSize: "20", color: "#00a6db", marginRight: 5 }}
          />
          {getVariantText(typeOfUse.title, "subtitile2", {
            wordBreak: "break-word",
          })}
        </div>
        <Divider
          orientation="vertical"
          flexItem
          style={{ margin: "20px 5px", backgroundColor: "#254a9a" }}
        />
        <div
          className={"d-flex align-items-center flex-wrap"}
          style={{ width: "30%" }}
        >
          <LocationOnIcon
            style={{ fontSize: "20", color: "#018075 ", marginRight: 5 }}
          />
          {getVariantText(sub.address, "subtitile2")}
        </div>
        <Box
          aria-label="subasset options button group"
          variant="text"
          style={{
            justifyContent: "flex-end",
            marginLeft: "auto",
          }}
          className={"d-flex align-items-center justify-content-center"}
        >
          <CustomIconButton
            id={`edit_subAsset_${index}`}
            icon={<EditIcon style={{ color: "#254a9a", fontSize: 25 }} />}
            permission={null}
            handleClick={() => props.onSubAssetEdit(sub)}
            tooltip={{
              title: getIntlTranslation("tooltip.edit-asset"),
            }}
            style={{
              borderRadius: 0,
              margin: 10,
              marginLeft: "auto",
            }}
          />
          <Divider
            orientation="vertical"
            style={{ border: "1px solid #d08f8e", height: "50%" }}
          />
          <CustomIconButton
            id={`delete_asset_${index}`}
            icon={
              <DeleteForeverIcon style={{ color: "#f50057", fontSize: 25 }} />
            }
            permission={null}
            handleClick={() => props.onSubAssetDelete(sub)}
            tooltip={{
              title: getIntlTranslation("tooltip.delete-asset"),
            }}
            style={{
              margin: 10,
              marginLeft: "auto",
            }}
          />
        </Box>
      </Box>
    );
  };

  const handleUndoTempVersion = () => {
    dispatch(
      getTemp({
        isTemp: false,
        assetName: "",
        assetIdent: "",
        reportVersionIdent: "",
        reportVersionObject: {},
      })
    );
  };

  const renderReportVersionSection = (m, index, asset) => {
    return (
      <ListItem
        key={m.reportOrder}
        style={{
          borderLeft:
            m.current && tempVersion.reportVersionIdent !== m.ident
              ? "4px solid rgb(211, 0, 56)"
              : tempVersion.reportVersionIdent === m.ident
              ? "4px solid rgb(242, 148, 17)"
              : "none",
          backgroundColor:
            m.current && tempVersion.reportVersionIdent !== m.ident
              ? "rgba(255, 42, 81, 0.19)"
              : tempVersion.reportVersionIdent === m.ident
              ? "rgb(255, 244, 229)"
              : "#ffff",
          margin: 10,
          width: "auto",
        }}
      >
        <ListItemText
          primary={
            <Typography
              variant="subtitle2"
              style={{
                fontWeight: "bold",
                color: "#254a9a",
              }}
            >
              {m.versionName}
            </Typography>
          }
        />
        <>
          <CustomIconButton
            id={`menu_button_${m.versionName}`}
            icon={
              <ArrowDropDownIcon style={{ color: "#d08f8e", fontSize: 20 }} />
            }
            permission={null}
            handleClick={(e) => handleReportVersionOptions(e, m)}
            tooltip={{
              title: getIntlTranslation("tooltip.edit"),
            }}
            style={{
              borderRadius: 0,
            }}
            aria-describedby={`prop_menu_${m.versionName}`}
          />
          <Popover
            id={`prop_menu_${m.versionName}`}
            open={openPopOver === m.ident}
            anchorEl={anchorEl}
            onClose={(e) => handleClose(e)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            <List>
              {!m.current && (
                <ListItemButton onClick={() => handleReportUpdate(m)}>
                  <ListItemText
                    primary={getTranslationVariantText(
                      "_mark as current",
                      "subtitle2",
                      {
                        fontWeight: "bold",
                        color: "#254a9a",
                      }
                    )}
                  />
                </ListItemButton>
              )}
              {tempVersion.reportVersionIdent !== m.ident && (
                <ListItemButton
                  onClick={() => {
                    handleTempOnclick(m, asset);
                  }}
                >
                  <ListItemText
                    primary={getTranslationVariantText(
                      "_mark as temp version",
                      "subtitle2",
                      {
                        fontWeight: "bold",
                        color: "#254a9a",
                      }
                    )}
                  />
                </ListItemButton>
              )}
              {tempVersion.reportVersionIdent !== m.ident && (
                <ListItemButton
                  onClick={() => {
                    setDeleteDialogState({
                      open: true,
                      version: m,
                      asset: asset,
                    });
                    handleClose(); // Close the popover
                  }}
                >
                  <ListItemText
                    primary={getTranslationVariantText(
                      "_delete report version",
                      "subtitle2",
                      {
                        fontWeight: "bold",
                        color: "#254a9a",
                      }
                    )}
                  />
                </ListItemButton>
              )}
              {tempVersion.reportVersionIdent === m.ident && (
                <ListItemButton
                  onClick={() => {
                    handleUndoTempVersion();
                  }}
                >
                  <ListItemText
                    primary={getTranslationVariantText(
                      "_undo temp version",
                      "subtitle2",
                      {
                        fontWeight: "bold",
                        color: "#254a9a",
                      }
                    )}
                  />
                </ListItemButton>
              )}

              <ListItemButton
                onClick={() => {
                  setOpenUpdateReportVersion({
                    open: true,
                    assetIdent: m.assetId,
                  });
                }}
              >
                <ListItemText
                  primary={getTranslationVariantText(
                    "_edit version name",
                    "subtitle2",
                    {
                      fontWeight: "bold",
                      color: "#254a9a",
                    }
                  )}
                />
              </ListItemButton>
              {openUpdateReportVersion && (
                <CreateReportVersion
                  open={openUpdateReportVersion.open}
                  buttonText="_save"
                  option="update"
                  assetIdent={openUpdateReportVersion.assetIdent}
                  onClose={() =>
                    setOpenUpdateReportVersion({
                      open: false,
                      assetIdent: null,
                    })
                  }
                  handleSave={(val) => handleUpdateVersionName(m, val)}
                />
              )}
            </List>
          </Popover>
        </>
      </ListItem>
    );
  };
  return (
    <div
      style={{ height: "100%", overflowY: "auto", overflowX: "hidden" }}
      className="custom-scrollBar"
    >
      <div
        style={{
          position: "sticky",
          top: 0,
          zIndex: 1500,
          background: "#C1D0F0",
        }}
        className="d-flex justify-center"
      >
        <CustomButton
          id={`create-asset-01`}
          buttonText={"_Create Asset"}
          variant="button"
          style={{ margin: 10 }}
          startIcon={<AddIcon sx={{ mr: 1 }} />}
          className="g-btn-primary"
          tooltip={{
            title: getIntlTranslation("tooltip.create-asset"),
          }}
          handleClick={(e) => props.openCreateAssetDialog()}
        />
      </div>
      {!!props.assetList.length > 0 &&
        props.assetList.map((asset, index) => {
          return (
            <>
              <Card
                key={`asset_${index}`}
                style={{ width: "100%", margin: 10 }}
                className="d-flex flex-column"
              >
                <Box className={"d-flex"} style={{ width: "100%" }}>
                  <div
                    className={"d-flex flex-column"}
                    style={{ margin: 10, width: "50%" }}
                  >
                    <div className={"d-flex align-items-center"}>
                      <span style={{ margin: 5 }}>
                        {getTranslationVariantText("_Asset", "body1", {
                          fontWeight: "bold",
                          color: "#254a9a",
                        })}
                      </span>
                      :
                      <span style={{ margin: 5 }}>
                        {getVariantText(asset.name, "body1", {
                          fontWeight: "bold",
                        })}
                      </span>
                    </div>
                    <div>
                      <div className={"d-flex align-items-center"}>
                        <LocationOnIcon
                          style={{
                            fontSize: "20",
                            color: "#018075 ",
                            marginRight: 5,
                          }}
                        />
                        {getVariantText(getAddress(asset.address), "caption")}
                      </div>
                    </div>
                  </div>
                  {!!asset.lastUpdate && asset.lastUpdatedByUser && (
                    <div style={{ margin: 10, width: "40%" }}>
                      <span style={{ margin: 5 }}>
                        {getTranslationVariantText("_User", "caption")}:
                      </span>
                      {getVariantText(asset.lastUpdatedByUser, "caption")}
                      {getTranslationVariantText("_last update", "caption")}:
                      {getVariantText(asset.lastUpdate, "caption")}
                    </div>
                  )}
                  <Box
                    aria-label="asset options button group"
                    variant="text"
                    style={{
                      justifyContent: "flex-end",
                      marginLeft: "auto",
                    }}
                    className={
                      "d-flex align-items-center justify-content-center"
                    }
                  >
                    <CustomIconButton
                      id={`generate_report_${index}`}
                      icon={
                        <AssignmentIcon
                          style={{ color: "#254a9a", fontSize: 25 }}
                        />
                      }
                      permission={null}
                      handleClick={() => props.onOpenReportGeneration(asset)}
                      tooltip={{
                        title: getIntlTranslation(
                          "tooltip.open_reports_dialog"
                        ),
                      }}
                      style={{
                        borderRadius: 0,
                        margin: 5,
                        marginLeft: "auto",
                      }}
                    />
                    <Divider
                      orientation="vertical"
                      style={{ border: "1px solid #d08f8e", height: "50%" }}
                    />
                    <CustomIconButton
                      id={`edit_asset_${index}`}
                      icon={
                        <EditIcon style={{ color: "#254a9a", fontSize: 25 }} />
                      }
                      permission={null}
                      handleClick={() => props.onEdit(asset)}
                      tooltip={{
                        title: getIntlTranslation("tooltip.edit-asset"),
                      }}
                      style={{
                        borderRadius: 0,
                        margin: 5,
                        marginLeft: "auto",
                      }}
                    />
                    <Divider
                      orientation="vertical"
                      style={{ border: "1px solid #d08f8e", height: "50%" }}
                    />
                    <CustomIconButton
                      id={`delete_asset_${index}`}
                      icon={
                        <DeleteForeverIcon
                          style={{ color: "#f50057", fontSize: 25 }}
                        />
                      }
                      permission={null}
                      handleClick={() => props.onDelete(asset)}
                      tooltip={{
                        title: getIntlTranslation("tooltip.delete-asset"),
                      }}
                      style={{
                        margin: 5,
                        marginLeft: "auto",
                      }}
                    />
                  </Box>
                </Box>
                <Divider component="div" style={{ width: "98%" }} />
                <Box style={{ width: "100%" }}>
                  <Box
                    className={"d-flex align-items-center"}
                    style={{ width: "100%" }}
                  >
                    <Box>
                      {getTranslationVariantText(
                        "List of Sub-Assets",
                        "subtitle2",
                        {
                          color: "#254a9a",
                          textTransform: "uppercase",
                          fontWeight: "bold",
                          padding: 10,
                        }
                      )}
                    </Box>
                    <Box style={{ marginLeft: "auto", padding: 10 }}>
                      <CustomButton
                        id="create-sub-asset-button"
                        dataIndex={asset.assetIdent}
                        style={{
                          float: "right",
                          color: "#fff",
                          background: "#254a9a",
                        }}
                        size="small"
                        className="g-btn-secondary"
                        variant="button"
                        handleClick={(event) => props.onCreateSubAsset(asset)}
                        endIcon={<AddCircleIcon />}
                        tooltip={{
                          title: getIntlTranslation("tooltip.Create Sub-Asset"),
                        }}
                        buttonText="_Create Sub-Asset"
                      />
                    </Box>
                  </Box>
                  {asset.subAssets.map((sub, index) => {
                    return (
                      <>
                        {renderSubAssetSection(asset, sub, index)}
                        {index < asset.subAssets.length - 1 && (
                          <Divider component="div" style={{ width: "98%" }} />
                        )}
                      </>
                    );
                  })}
                </Box>
                <Divider variant="middle" className="g-divider-vertical" />
                <Box
                  className="d-flex flex-row align-items-baseline"
                  style={{ width: "100%" }}
                >
                  <List
                    className="d-flex flex-row flex-wrap"
                    style={{ width: "80%" }}
                  >
                    <ListItem key={"version"} style={{ width: "auto" }}>
                      <ListItemText
                        primary={getTranslationVariantText(
                          "version",
                          "subtitle2",
                          {
                            fontWeight: "bold",
                            color: "#254a9a",
                          }
                        )}
                      />
                    </ListItem>
                    {asset.reportVersion
                      .sort((a, b) => a.reportOrder - b.reportOrder)
                      .map((m, index) => {
                        return renderReportVersionSection(m, index, asset);
                      })}
                  </List>
                  <div style={{ marginLeft: "auto", padding: 10 }}>
                    <CustomButton
                      id="create_report_version"
                      buttonText={"_create-report-version"}
                      variant="button"
                      className="btn g-btn-secondary"
                      endIcon={<AssessmentIcon style={{ color: "#254a9a" }} />}
                      tooltip={{
                        title: getIntlTranslation(
                          "tooltip.create-report-verion"
                        ),
                      }}
                      handleClick={() =>
                        props.openReportVersion({
                          open: true,
                          assetIdent: asset.assetIdent,
                        })
                      }
                    />
                  </div>
                  <DeleteConfirmationDialog
                    open={deleteDialogState.open}
                    onClose={() =>
                      setDeleteDialogState({
                        open: false,
                        version: null,
                        asset: null,
                      })
                    }
                    onConfirm={() =>
                      handleDeleteVersion(
                        deleteDialogState.version,
                        deleteDialogState.asset
                      )
                    }
                    versionName={deleteDialogState.version?.versionName || ""}
                  />
                </Box>
              </Card>
            </>
          );
        })}
    </div>
  );
};

AssetOverviewContainer.propTypes = {
  assetList: PropTypes.array,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
  onCreateSubAsset: PropTypes.func,
  openReportVersion: PropTypes.func,
  onSubAssetEdit: PropTypes.func,
  onSubAssetDelete: PropTypes.func,
  openCreateAssetDialog: PropTypes.func,
  onOpenReportGeneration: PropTypes.func,
};

export default AssetOverviewContainer;
