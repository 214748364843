import { Box, InputAdornment, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { NumericFormat } from "react-number-format";
import { useSelector } from "react-redux";
import { getIntlTranslation } from "../../../../utils/helper";


const AreaInput = ({
  id,
  key,
  disabled = false,
  node,
  handleChange,
}) => {
  const [value, setValue] = useState(node.value);
  const scope = useSelector((state) => state.container.scope);
  const locale = scope.projectLanguageCode === "en" ? "en-US" : "de-De";
  const numberFormat = new Intl.NumberFormat(locale);

  const thousandSeparator =  scope.projectLanguageCode === "en" ? "," : ".";
  const  decimalSeparators = scope.projectLanguageCode === "en" ? "." : ",";
  const pattern = scope.projectLanguageCode === "en" ? '#,###.##' : '#.###,##';  
  


  const onChange = (values) => {
    const { value } = values;
    setValue(value);
  }

  return (
    <Box className="d-flex">
      <NumericFormat
        id={id}
        key={key}
        variant="outlined"
        disabled={disabled}
        fullWidth
        customInput={TextField}
        thousandSeparator={thousandSeparator}
        decimalSeparator={decimalSeparators}
        allowedDecimalSeparators={[decimalSeparators]}
        decimalScale={2}  // Changed to allow 2 decimals
        allowNegative={false}
        value={value}
        onValueChange={onChange}
        onBlur={(e) => {
          if(value !== node.value){
            handleChange(value, node, true);
          }
        }}
        InputProps={{
          endAdornment: <InputAdornment position="end">m²</InputAdornment>,
          inputProps: {
            "aria-label": "",
          },
        }}
        FormHelperTextProps={{
          classes: {
            root: "p-1 m-0",
          },
        }}
        multiline
        helperText={
          <Box className="d-flex flex-column w-100">
            <Typography variant="caption" style={{color:"#244a9a"}}>
              {getIntlTranslation("_numeric-format")} : {pattern}
            </Typography>
            <Typography variant="caption">
              {getIntlTranslation("_unknown-value")}
            </Typography>
          </Box>
        }
      />
    </Box>

    //"Input is auto Formatted in the pattern  or Add '-' if unknown"
  );
};

export default AreaInput;
