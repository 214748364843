import React from 'react';
import PropTypes from "prop-types";
import { 
  Dialog, 
  DialogContent, 
  DialogActions, 
  Typography, 
  Button,
  AppBar,
  Toolbar,
  IconButton
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import WarningIcon from '@mui/icons-material/Warning';
import { styled } from '@mui/material/styles';
import { 
  getTranslationVariantText,
  getVariantText,
  getIntlTranslation 
} from "../../../../../utils/helper";

const StyledAppBar = styled(AppBar)({
  backgroundColor: '#d08f8e',
  position: 'relative',
  minHeight: '56px',
  padding: 0,
  boxShadow: 'none'
});

const StyledToolbar = styled(Toolbar)({
  display: 'flex',
  justifyContent: 'space-between',
  minHeight: '56px',
  padding: '0 16px',
  borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
});

const StyledDialogContent = styled(DialogContent)({
  padding: '20px 24px',
  backgroundColor: '#fff',
  '& p': {
    display: 'inline',
    whiteSpace: 'normal'
  }
});

const StyledDialogActions = styled(DialogActions)({
  padding: '8px 24px 16px',
  backgroundColor: '#fff',
  justifyContent: 'flex-end'
});

const CancelButton = styled(Button)({
  backgroundColor: 'transparent',
  color: '#000',
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
  },
  fontWeight: 700,
  padding: '6px 16px'
});

const DeleteButton = styled(Button)({
  backgroundColor: '#dc3545',
  color: '#fff',
  '&:hover': {
    backgroundColor: '#c82333',
  },
  fontWeight: 700,
  padding: '6px 16px',
  marginLeft: '8px'
});

const HeaderTypography = styled(Typography)({
  display: 'flex',
  alignItems: 'center',
  fontSize: '20px',
  fontWeight: 700,
  '& .MuiSvgIcon-root': {
    marginRight: '8px',
    fontSize: '24px'
  }
});

const ContentTypography = styled(Typography)({
  fontWeight: 600,
  fontSize: '16px',
  lineHeight: 1.5,
  display: 'inline-block'  
});

const DeleteConfirmationDialog = ({ open, onClose, onConfirm, versionName }) => {
  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      maxWidth="sm"
      fullWidth
    >
      <StyledAppBar>
        <StyledToolbar>
          <HeaderTypography>
            <WarningIcon />
            {getTranslationVariantText("_confrim-deletion", "h6", {
              fontWeight: 700
            })}
          </HeaderTypography>
          <IconButton
            edge="end"
            color="inherit"
            onClick={onClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </StyledToolbar>
      </StyledAppBar>
      
      <StyledDialogContent>
        <ContentTypography>
          {getTranslationVariantText("_delete report version-1", "subtitle2", {
            fontWeight: 600,
            display: 'inline'
          })}
          {' "'}
          <strong>{versionName}</strong>
          {'". '}
          {getTranslationVariantText("_delete report version-2", "subtitle2", {
            fontWeight: 600,
            display: 'inline'
          })}
        </ContentTypography>
      </StyledDialogContent>
      
      <StyledDialogActions>
        <CancelButton onClick={onClose}>
          {getTranslationVariantText("_cancel", "button", {
            fontWeight: 700
          })}
        </CancelButton>
        <DeleteButton onClick={onConfirm} variant="contained">
          {getTranslationVariantText("_delete", "button", {
            fontWeight: 700,
            color: '#fff'
          })}
        </DeleteButton>
      </StyledDialogActions>
    </Dialog>
  );
};

DeleteConfirmationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  versionName: PropTypes.string.isRequired
};

export default DeleteConfirmationDialog;