import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, TextField, Typography } from '@mui/material';
import { NumericFormat } from 'react-number-format';
import { useSelector } from 'react-redux';
import { getIntlTranslation } from '../../../../utils/helper';

const NumberInput = ({id, key, disabled, node, handleChange}) => {
  const [value, setValue] = useState(node.value);
  const scope = useSelector((state) => state.container.scope);
  const locale = scope.projectLanguageCode === "en" ? "en-US" : "de-De";
  const numberFormat = new Intl.NumberFormat(locale);
  const thousandSeparator = scope.projectLanguageCode === "en" ? "," : ".";
  const decimalSeparators = scope.projectLanguageCode === "en" ? "." : ",";
  const pattern = scope.projectLanguageCode === "en" ? '#,###' : '#.###';  

  const onChange = (values) => {
    const { value } = values;
    if (value === '-') {
      setValue(value);
    } else {
      setValue(value.replace(/-/g, ''));
    }
  }

  return (
    <Box className="d-flex w-100">
      <NumericFormat
        id={id}
        key={key}
        variant="outlined"
        disabled={disabled}
        fullWidth 
        customInput={TextField} 
        thousandSeparator={thousandSeparator}
        decimalSeparator={decimalSeparators}
        allowedDecimalSeparators={[decimalSeparators]}
        decimalScale={0}  // Changed to no decimals
        fixedDecimalScale  // Added to enforce no decimals
        allowNegative={false}
        value={value}
        onValueChange={onChange}
        onBlur={(e) => {
          if(value !== node.value){
            handleChange(value, node, true);
          }
        }}
        InputLabelProps={{
          shrink: true,
        }}
        FormHelperTextProps={{
          classes: {
            root: "p-1 m-0",
          },
        }}
        helperText={
          <Box className="d-flex flex-column w-100">
            <Typography variant="caption" style={{color:"#244a9a"}}>
              {getIntlTranslation("_numeric-format")} : {pattern}
            </Typography>
            <Typography variant="caption">
              {getIntlTranslation("_unknown-value")}
            </Typography>
          </Box>
        }
      />
    </Box>
  );
};

export default NumberInput;